<template>
  <Swiper
    ref="swiper"
    class="swiper twy--mx-4 twy-p-4 twy-bg-transparent"
    :options="swiperOption"
    :autoUpdate="false"
  >
    <SwiperSlide
      v-for="(card, index) in cards"
      :key="index"
      class="card twy-h-auto twy-p-4 twy-border-2 twy-rounded-lg twy-select-none twy-not-italic twy-whitespace-normal twy-bg-white"
    >
      <div class="twy-flex twy-flex-col twy-h-full">
        <CImage
          class="card-image swiper-lazy twy-grow-0 twy-rounded-lg twy-max-h-32 twy-object-cover"
          :data-src="card.photo"
        />

        <div class="twy-text-black twy-text-left">
          <div class="card-title twy-mt-2 twy-text-base twy-font-bold">
            {{ card.title }}
          </div>
          <div class="card-description twy-text-sm twy-mt-0.5">
            {{ card.description }}
          </div>
        </div>

        <div class="twy-w-full twy-h-full twy-flex twy-flex-col">
          <CButton
            v-if="card.seeMore"
            :href="card.seeMore.url"
            open-on-new-tab
            variant="primary"
            class="card-btn twy-w-full twy-justify-center twy-mt-2 twy-px-2 twy-py-1 twy-rounded-lg twy-bg-blue-900 twy-text-sm"
          >
            {{ card.seeMore.title }}
          </CButton>

          <template v-if="card.actions && card.actions.length">
            <CButton
              v-for="(action, index) in card.actions"
              :key="index"
              :href="action.url"
              open-on-new-tab
              :variant="card.level || 'secondary'"
              class="card-btn twy-w-full twy-justify-center twy-mt-2 twy-px-2 twy-py-1 twy-rounded-lg twy-text-sm"
              :class="bgs[card.level || 0]"
            >
              {{ action.title }}
            </CButton>
          </template>
        </div>
      </div>
    </SwiperSlide>

    <template #button-prev>
      <div class="swiper-button-prev twy-left-0.5 twy-transform twy-rotate-180">
        <CIcons icon="arrow" />
      </div>
    </template>

    <template #button-next>
      <div class="swiper-button-next twy-right-0.5">
        <CIcons icon="arrow" />
      </div>
    </template>
  </Swiper>
</template>

<script>
import messageMixin from "./message-mixin.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import CImage from "@/components/common/c-image/index.vue";
import CIcons from "@/components/common/c-icons/index.vue";
import CButton from "@/components/common/c-button/index.vue";

export default {
  mixins: [messageMixin],
  components: {
    Swiper,
    SwiperSlide,
    CImage,
    CIcons,
    CButton
  },
  data() {
    return {
      swiperOption: {
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
          loadOnTransitionStart: true
        },
        slidesPerView: 1.15,
        spaceBetween: 15,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    cards() {
      return this.message.data.content;
    },
    bgs() {
      return {
        1: "twy-bg-green-500",
        0: "twy-bg-blue-900"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/css/swiper.min.css";

.swiper-button-prev,
.swiper-button-next {
  @apply twy-h-7 twy-rounded-full twy-bg-blue-900 twy-text-xl;
  color: var(--yed-cards-navigation-btn-color, white);

  &::after {
    content: none;
  }
}

.card-btn {
  color: var(--yed-cards-btn-color, white);
}

.card-description {
  // https://css-tricks.com/almanac/properties/l/line-clamp/
  // https://caniuse.com/?search=line-clamp
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
</style>
