<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="14.000000pt"
    height="14.000000pt"
    viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      fill="#FF0000"
      stroke="none"
    >
      <path
        d="M125 310 c-73 -16 -117 -73 -117 -150 0 -90 61 -152 152 -152 90 0
152 61 152 152 0 102 -88 173 -187 150z m100 -150 l0 -65 -65 0 -65 0 -3 54
c-5 80 -2 82 70 79 l63 -3 0 -65z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "StopIcon"
};
</script>
