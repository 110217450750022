import Vue from "vue";
import Vuex from "vuex";

import cookies from "./modules/cookies";
import i18n from "./modules/i18n";
import audio from "./modules/audio";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    cookies,
    i18n,
    audio
  }
});
