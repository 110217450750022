<template>
  <div>
    <Suggestions
      :suggestions="suggestions"
      :colors="colors"
      @sendSuggestion="_submitSuggestion"
    />
    <div
      v-if="file"
      class="file-container"
      :style="{
        backgroundColor: colors.userInput.text,
        color: colors.userInput.bg
      }"
    >
      <span class="icon-file-message"
        ><img :src="icons.file.img" :alt="icons.file.name" height="15"
      /></span>
      {{ file.name }}
      <span class="delete-file-message" @click="cancelFile()"
        ><img
          :src="icons.closeSvg.img"
          :alt="icons.closeSvg.name"
          height="10"
          title="Remove the file"
      /></span>
    </div>
    <form
      class="sc-user-input"
      :class="{ active: inputActive }"
      :style="{ background: colors.userInput.bg }"
    >
      <div
        ref="userInput"
        role="button"
        tabIndex="0"
        contentEditable="true"
        :placeholder="placeholder"
        class="sc-user-input--text"
        :style="{ color: colors.userInput.text }"
        @focus="setInputActive(true)"
        @blur="setInputActive(false)"
        @keydown="handleKey"
        @focusUserInput="focusUserInput()"
        @paste="handlePaste"
      ></div>
      <div class="sc-user-input--buttons">
        <div v-if="showEmoji && !isEditing" class="sc-user-input--button">
          <EmojiIcon
            :on-emoji-picked="_handleEmojiPicked"
            :color="colors.userInput.text"
          />
        </div>
        <div v-if="showFile && !isEditing" class="sc-user-input--button">
          <FileIcons
            :on-change="_handleFileSubmit"
            :color="colors.userInput.text"
          />
        </div>
        <div v-if="isEditing" class="sc-user-input--button">
          <UserInputButton
            :color="colors.userInput.text"
            tooltip="Cancel"
            @click.native.prevent="_editFinish"
          >
            <IconCross />
          </UserInputButton>
        </div>
        <div class="sc-user-input--button twy-text-red-600">
          <UserInputButton
            v-if="hasAudio && !isRecording"
            :color="audioColor"
            tooltip="Record"
            @click.native.prevent="$emit('edit')"
          >
            <AudioIcon />
          </UserInputButton>
          <UserInputButton
            v-if="hasAudio && isRecording"
            :color="audioColor"
            tooltip="Stop"
            @click.native.prevent="$emit('edit')"
          >
            <StopIcon />
          </UserInputButton>
          <UserInputButton
            v-if="!hasAudio"
            :color="colors.userInput.text"
            tooltip="Send"
            @click.native.prevent="_submitText"
          >
            <IconSend />
          </UserInputButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import colors from "../colors";
/**
 * This is a mixin component for extending the UserInput.
 * The template overrides the original one.
 * You can add methods and acceso to the same props.
 * @see https://github.com/mattmezza/vue-beautiful-chat/blob/master/src/UserInput.vue
 */
import AudioIcon from "./audioIcon.vue";
import StopIcon from "./StopIcon.vue";
export default {
  components: {
    AudioIcon,
    StopIcon
  },
  prop: colors,
  computed: {
    audioColor() {
      return this.isRecording ? "red" : this.colors.userInput.text;
    },
    ...mapGetters("audio", ["hasAudio", "isRecording"])
  },
  methods: {
    handlePaste(e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      this.$refs.userInput.textContent += text;
    }
  }
};
</script>
