<template>
  <div
    class="twy-absolute twy-inset-0 twy-bg-black twy-bg-opacity-70 twy-z-20  yed-legal twy-rounded-2xl twy-p-4 twy-flex twy-justify-center twy-items-center"
  >
    <div
      class="twy-z-21 twy-w-full twy-bg-white twy-text-black tw-y-1/2 twy-border-2 twy-rounded-md twy-border-black-500 twy-p-4"
    >
      <p v-html="message"></p>
      <div class="twy-mt-8 twy-flex twy-flex-wrap twy-justify-around">
        <CButton
          variant="accept"
          @click="onClick()"
          class="twy-w-1/3 twy-justify-center"
        >
          {{ button }}
        </CButton>
      </div>
    </div>
  </div>
</template>
<script>
import CButton from "../../common/c-button/index.vue";
export default {
  components: { CButton },
  name: "InactivityAlert",
  data: function() {
    return {
      button: "OK"
    };
  },
  props: {
    message: {
      type: String,
      default: "session_expired"
    }
  },
  methods: {
    onClick() {
      this.$emit("accept", true);
    }
  }
};
</script>
