<template>
  <g
    v-if="hairStyle == 'short'"
    transform="translate(-10, 0)"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      transform="translate(151.500000, 77.000000) scale(-1, 1) translate(-151.500000, -77.000000) translate(57.000000, 1.000000)"
    >
      <path
        d="M176.947293,114.821412 C198.562333,114.821168 216.025224,115.319374 216.025224,113.539573 C216.025224,111.759773 198.565237,109.586336 176.950197,109.586581 C155.335157,109.586825 137.75024,111.760658 137.75024,113.540458 C137.75024,115.320258 155.332252,114.821656 176.947293,114.821412 Z"
        transform="translate(176.887732, 112.236233) scale(-1, -1) rotate(-89.000000) translate(-176.887732, -112.236233) "
      />
      <path
        d="M93.3466778,141.612071 C114.959032,141.606555 132.486899,111.867866 132.496268,75.1888512 C132.50563,38.509836 115.059206,-6.28103737 93.4468522,-6.27552103 C71.8344983,-6.27000469 54.2403732,38.5298124 54.2310037,75.2088277 C54.2216418,111.887843 71.7343239,141.617588 93.3466778,141.612071 Z"
        transform="translate(93.363636, 67.668275) scale(1, -1) rotate(-64.000000) translate(-93.363636, -67.668275) "
      />
      <path
        d="M82.9729896,152.621831 C97.405462,152.619217 109.11068,122.884937 109.117328,86.2084577 C109.123973,49.5319785 87.870169,6.87351747 73.4376965,6.87613137 C59.0052241,6.87874527 79.2393692,3.38246231 56.8527205,86.2179235 C34.4660718,169.053385 68.5405172,152.624445 82.9729896,152.621831 Z"
        transform="translate(79.276971, 80.402082) scale(1, -1) rotate(-73.000000) translate(-79.276971, -80.402082) "
      />
      <path
        d="M87.7098621,131.917774 C97.6636663,131.915971 105.738207,102.182349 105.744855,65.5058693 C105.751499,28.8293901 91.0955502,-13.8302658 81.141746,-13.8284631 C71.1879418,-13.8266603 85.1432467,-17.3218061 69.6989392,65.5123977 C54.2546317,148.346602 77.7560579,131.919576 87.7098621,131.917774 Z"
        transform="translate(85.163299, 59.697248) scale(1, -1) rotate(-73.000000) translate(-85.163299, -59.697248) "
      />
      <path
        d="M161.808768,110.364945 C210.444001,101.59471 202.273919,94.2563273 187.276371,87.4758635 C172.278823,80.6953998 183.663063,70.1493168 164.306859,66.2490572 C144.950655,62.3487977 132.133929,72.5889419 132.133929,86.4200929 C132.133929,100.251244 140.399885,113.373586 161.808768,110.364945 Z"
        transform="translate(165.498352, 88.105699) scale(1, -1) rotate(-89.000000) translate(-165.498352, -88.105699) "
      />
    </g>
    <path
      d="M199.943036,105.677406 C200.454267,133.400399 197.467511,155.930519 193.271931,156 C189.076351,156.069159 183.539416,133.759951 183.028186,106.036959 C182.516955,78.3139667 189.076351,73.0700222 193.271931,73 C197.467511,72.9313824 199.431805,77.9544142 199.943036,105.677406 Z"
    />
    <path
      d="M68.9907653,81.8727544 C69.1901167,93.4705159 66.1377483,102.929328 62.1731097,103 C58.2084711,103.069879 53.2055088,93.7873531 53.0061574,82.1895915 C52.806806,70.59183 57.486556,61.0706689 61.4511946,61 C65.4158332,60.9301187 68.791414,70.2749928 68.9907653,81.8727544 Z"
    />
    <path
      d="M205.990765,81.8727544 C206.190117,93.4705159 203.137748,102.929328 199.17311,103 C195.208471,103.069879 190.205509,93.7873531 190.006157,82.1895915 C189.806806,70.59183 194.486556,61.0706689 198.451195,61 C202.415833,60.9301187 205.791414,70.2749928 205.990765,81.8727544 Z"
    />
  </g>

  <g
    v-else-if="hairStyle == 'half-bald' || hairStyle == 'bald'"
    fill-rule="evenodd"
    transform="translate(0, 35) scale(.95)"
  >
    <path
      d="M68.9907653,81.8727544 C69.1901167,93.4705159 66.1377483,102.929328 62.1731097,103 C58.2084711,103.069879 53.2055088,93.7873531 53.0061574,82.1895915 C52.806806,70.59183 57.486556,61.0706689 61.4511946,61 C65.4158332,60.9301187 68.791414,70.2749928 68.9907653,81.8727544 Z"
    />
    <path
      d="M205.990765,81.8727544 C206.190117,93.4705159 203.137748,102.929328 199.17311,103 C195.208471,103.069879 190.205509,93.7873531 190.006157,82.1895915 C189.806806,70.59183 194.486556,61.0706689 198.451195,61 C202.415833,60.9301187 205.791414,70.2749928 205.990765,81.8727544 Z"
    />
  </g>
  <g
    v-else-if="hairStyle == 'bald'"
    fill-rule="evenodd"
    transform="translate(0, 30) scale(.95)"
  >
    <path
      d="M68.9907653,81.8727544 C69.1901167,93.4705159 66.1377483,102.929328 62.1731097,103 C58.2084711,103.069879 53.2055088,93.7873531 53.0061574,82.1895915 C52.806806,70.59183 57.486556,61.0706689 61.4511946,61 C65.4158332,60.9301187 68.791414,70.2749928 68.9907653,81.8727544 Z"
    />
    <path
      d="M205.990765,81.8727544 C206.190117,93.4705159 203.137748,102.929328 199.17311,103 C195.208471,103.069879 190.205509,93.7873531 190.006157,82.1895915 C189.806806,70.59183 194.486556,61.0706689 198.451195,61 C202.415833,60.9301187 205.791414,70.2749928 205.990765,81.8727544 Z"
    />
  </g>
  <g
    v-else
    transform="translate(-8.5, 24) scale(1, 1)"
    fill="none"
    fill-rule="evenodd"
  >
    <path
      d="M171.496405,23.0224859 C182.204562,45.234771 167.542856,74.759655 138.748569,88.9681601 C118.50917,98.9552663 75.5597815,102.286623 57.1913434,95.2812293 C49.4272823,92.3201551 58.6119319,86.9738174 55.4304993,80.3744662 C44.7223423,58.1621812 71.1766152,22.7388654 99.9709023,8.53036031 C128.765189,-5.67814479 160.788248,0.810200842 171.496405,23.0224859 Z"
    />
    <path
      d="M203.784342,68.2361798 C195.869898,117.048433 192.407495,112.506222 190.150863,93.9471565 C187.894231,75.3880908 152.203831,79.2352902 152.005749,67.8643845 C151.807667,56.4934788 156.703574,28.2439793 170.522745,28.0022808 C184.341915,27.7605822 206.417007,46.764252 203.784342,68.2361798 Z"
    />
  </g>
</template>

<script>
export default {
  props: {
    hairStyle: {
      type: String,
      default: ""
    }
  }
};
</script>
