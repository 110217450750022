import { defaultLanguage, fallbackLanguage } from "@/common/constants.js";

const clearStyles = dirtyString =>
  JSON.parse(JSON.stringify(dirtyString).replace(/style=.(.*?)."/g, ""));
//const clearTags = str => str.replace(/(<([^>]+)>)/gi, "");
const transformFromBack = arr =>
  arr.texts.reduce((accum, current) => {
    const { lang_code, text, title } = current;
    accum[lang_code] = { text: text, title: title };
    return accum;
  }, {});

const setOnlyValue = (obj, initialVal, endVal) =>
  Object.keys(obj).reduce((accum, current) => {
    accum[current] = { [endVal]: obj[current][initialVal] };
    return accum;
  }, {});
const groupItems = arr =>
  Object.keys(arr[0]).reduce(
    (accumNewOb, currentLang) => ({
      ...accumNewOb,
      [currentLang]: arr.reduce(
        (accum, current) => ({ ...accum, ...current[currentLang] }),
        {}
      )
    }),
    {}
  );
const adaptArray = obj => {
  return {
    accept: setOnlyValue(obj.accept, "text", "title"),
    cancel: setOnlyValue(obj.cancel, "text", "title"),
    email_required: setOnlyValue(obj.email_required, "text", "title"),
    chatBotTitle: setOnlyValue(obj.chatbot_title, "text", "title"),
    writeMessage: setOnlyValue(obj.writeamessage_text, "text", "send"),
    langHeader: setOnlyValue(
      obj.langheader_text || {
        es: {
          text:
            "Habla conmigo en Español, Catalán o Inglés. Cambio de idioma de forma natural."
        },
        en: {
          text:
            "Speak with me in Spanish, Catalan or English. I change language naturally."
        },
        ca: {
          text:
            "Parla amb mi en Espanyol, Català o Anglès. Canvi d'idioma de forma natural."
        }
      },
      "text",
      "title"
    ),
    forceLang: setOnlyValue(obj.always_speak_this_lang, "text", "title"),
    cookies: groupItems([
      setOnlyValue(obj.CSA_title, "text", "title"),
      setOnlyValue(obj.CSA_intro, "text", "text"),
      setOnlyValue(obj.CSA_URLfriendlytext, "text", "link_title"),
      setOnlyValue(obj.CSA_URL, "text", "link"),
      setOnlyValue(obj.CSA_Yesbutton, "text", "confirm"),
      setOnlyValue(obj.CSA_NoButton, "text", "reject")
    ]),
    removeConversation: groupItems([
      setOnlyValue(obj.forget_me, "text", "title"),
      setOnlyValue(obj.ryousure_text, "text", "text"),
      setOnlyValue(obj.Ryousure_YES, "text", "confirm"),
      setOnlyValue(obj.Ryousure_NO, "text", "reject")
    ])
  };
};

// module for traductions and text inside the web
export default {
  namespaced: true,
  state: () => ({
    lang: {
      current: defaultLanguage,
      default: fallbackLanguage,
      options: ["en", "es"]
    },
    text: {
      // list of test
      writeMessage: {
        es: {
          send: "Hazme una pregunta."
        },
        en: {
          send: "Ask me a question."
        },
        ca: {
          send: "Fes-me una pregunta."
        }
      },
      langHeader: {
        es: {
          text:
            "Habla conmigo en Español, Catalán o Inglés. Cambio de idioma de forma natural."
        },
        en: {
          text:
            "Speak with me in English, Spanish or Catalan. I change language naturally."
        },
        ca: {
          text:
            "Parla amb mi en Català, Espanyol o Anglès. Canvi d'idioma de forma natural."
        }
      },
      forceLang: {
        es: {
          title: "Contestar siempre en Español"
        },
        en: {
          title: "Answer always in English"
        },
        ca: {
          title: "Esborra la meva conversa"
        }
      },
      cookies: {
        es: {
          title: "Olvidar mi conversación",
          text: "ES ¿Seguro que quieres borrar tu conversación?",
          link_title: "Teminos y condiciones",
          link: "",
          confirm: "Aceptar",
          reject: "Cancelar"
        },
        en: {
          title: "Forget my conversation",
          text: "EN ¿Seguro que quieres borrar tu conversación?",
          link_title: "Terms of Use and Privacy",
          link: "",
          confirm: "Aceptar",
          reject: "Cancelar"
        },
        ca: {
          title: "Esborra la meva conversa",
          text: "CA ¿Seguro que quieres borrar tu conversación?",
          link_title: "Privacitat",
          link: "",
          confirm: "Aceptar",
          reject: "Cancelar"
        }
      },
      removeConversation: {
        es: {
          title: "Olvidar mi conversación",
          text: "ES ¿Seguro que quieres borrar tu conversación?",
          confirm: "borrar",
          reject: "cancelar"
        },
        en: {
          title: "Forget my conversation",
          text: "EN ¿Seguro que quieres borrar tu conversación?",
          confirm: "borrar",
          reject: "cancelar"
        },
        ca: {
          title: "Esborra la meva conversa",
          text: "CA ¿Seguro que quieres borrar tu conversación?",
          confirm: "borrar",
          reject: "cancelar"
        }
      }
    }
  }),
  mutations: {
    SET_LANG_OPTIONS(state, payload) {
      state.lang.options = payload;
    },
    SET_DEFAULT_LANG(state, payload) {
      state.lang.default = payload;
    },
    SET_CURRENT_LANG(state, payload) {
      state.lang.current = payload;
    },
    SET_TRANSLATIONS(state, payload) {
      state.text = adaptArray(payload);
    }
  },
  actions: {
    setLangOptions({ commit }, payload) {
      const defaultApiLang = payload?.default_lang;
      if (defaultApiLang) {
        commit("SET_DEFAULT_LANG", defaultApiLang);
      }
      const keyLang = payload?.key_langs;
      const optionsLang = payload?.translated_langs;
      const defaultApiOptions = keyLang.concat(optionsLang);
      if (defaultApiOptions) {
        const updatedValues = defaultApiOptions.map(lang => lang.code);
        commit("SET_LANG_OPTIONS", updatedValues);
      }
    },
    setCurrentLang({ commit }, payload) {
      commit("SET_CURRENT_LANG", payload);
    },
    setTranslations({ commit }, payload) {
      // transfrom from ENDPOINT to current format
      const transfromedArray = payload.reduce((accum, current) => {
        accum[`${current.slug}`] = transformFromBack(current);
        return accum;
      }, {});
      commit("SET_TRANSLATIONS", transfromedArray);
    }
  },
  getters: {
    currentLang(state) {
      return state.lang.current;
    },
    defaultLang(state) {
      return state.lang.default;
    },
    optionsLang(state) {
      return state.lang.options;
    },
    transLang(state, getters) {
      return lang => {
        const transKeys = Object.keys(state.text);
        var { currentLang, defaultLang } = getters;
        currentLang = lang;
        const transByLang = (arr, setlang) =>
          arr.reduce((accumulator, currentValue) => {
            if (state.text[currentValue][setlang]) {
              accumulator[currentValue] = clearStyles(
                state.text[currentValue][setlang]
              );
            }
            return accumulator;
          }, {});
        const transOfCurrentLang = transByLang(transKeys, currentLang);
        const transOfDefaultLang = transByLang(transKeys, defaultLang);
        // In case the languade doesn't have all traductions
        // -first load all default lang
        // -overwrite them with current lang
        return { ...transOfDefaultLang, ...transOfCurrentLang };
      };
    },
    trans(state, getters) {
      const transKeys = Object.keys(state.text);
      const { currentLang, defaultLang } = getters;
      const transByLang = (arr, setlang) =>
        arr.reduce((accumulator, currentValue) => {
          if (state.text[currentValue][setlang]) {
            accumulator[currentValue] = clearStyles(
              state.text[currentValue][setlang]
            );
          }
          return accumulator;
        }, {});
      const transOfCurrentLang = transByLang(transKeys, currentLang);
      const transOfDefaultLang = transByLang(transKeys, defaultLang);
      // In case the languade doesn't have all traductions
      // -first load all default lang
      // -overwrite them with current lang
      return { ...transOfDefaultLang, ...transOfCurrentLang };
    }
  }
};
