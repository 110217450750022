<template>
  <component
    :is="tag"
    :href="href"
    :class="[variantStyles, sizeStyles, iconStyles]"
    class="twy-inline-flex twy-items-center"
    v-bind="computedProps"
    @click="handleClick"
  >
    <c-icons
      v-if="iconBefore"
      :icon="iconBefore"
      class="twy-mr-1"
      :class="[buildIconDirection()]"
    />
    <slot />
    <c-icons v-if="iconAfter" :icon="iconAfter" class="twy-ml-1" />
  </component>
</template>

<script>
import cIcons from "@/components/common/c-icons/index.vue";

export default {
  name: "CButton",
  components: {
    cIcons
  },
  props: {
    href: {
      type: String,
      required: false,
      default: null
    },
    openOnNewTab: {
      type: Boolean,
      default: true
    },
    iconAfter: {
      type: String,
      required: false,
      default: undefined
    },
    iconBefore: {
      type: String,
      required: false,
      default: undefined
    },
    variant: {
      type: String,
      required: false,
      default: "primary"
      // validator(value) {
      //   return ['primary', 'outline', 'link'].includes(value);
      // },
    },
    size: {
      type: String,
      required: false,
      default: "md",
      validator(value) {
        return ["xs", "sm", "md", "lg"].includes(value);
      }
    }
  },
  data() {
    return {
      variantStyles: this.buildVariantStyles(),
      sizeStyles: this.buildSizeStyles(),
      iconStyles: this.buildIconStyles()
    };
  },
  computed: {
    tag() {
      /* eslint no-useless-escape: "error" */
      const pattern = /(ftp|http|https):\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?/;
      const validUrl = pattern.test(this.href);
      if (this.href && validUrl) {
        return "a";
      }
      return "button";
    },
    computedProps() {
      return {
        ...(this.href && this.openOnNewTab ? { target: "_blank" } : {})
      };
    }
  },
  methods: {
    buildIconDirection() {
      const rotateIconsList = ["arrow"];
      if (rotateIconsList.includes(this.iconBefore)) {
        return "twy-transform twy-rotate-180 twy-text-xs";
      }
      return "";
    },
    buildIconStyles() {
      const baseIcon =
        this.iconAfter || this.iconBefore
          ? "twy-flex twy-justify-between twy-items-center"
          : "";
      return baseIcon;
    },
    buildVariantStyles() {
      const baseButton = "ds-btn";
      const baseVariant = this.variant ? ` ${baseButton}-${this.variant}` : "";

      return baseButton + baseVariant;
    },
    buildSizeStyles() {
      const withPadding = ["primary", "outline"].includes(this.variant);
      const basePadding = withPadding ? ` ${withPadding ? "padding" : ""}` : "";
      const baseSize = this.size ? ` btn-size--${this.size}` : "";

      return baseSize + basePadding;
    },
    handleClick(ev) {
      this.$emit("click", ev);
    }
  }
};
</script>

<style lang="scss" scoped>
.ds-btn {
  &:hover,
  &:focus {
    @apply twy-outline-none twy-shadow-lg;
  }
}
/* BTN BASE */
.btn {
  @apply twy-rounded-md twy-font-mono;
  &:disabled {
    @apply twy-bg-gray-100 twy-cursor-not-allowed;
  }
}

.ds-btn-accept,
.ds-btn-cancel {
  padding: 0.5em 0.75em;
  opacity: 0.9;
  transition: 0.3s ease-in-out;
  &:focus,
  &:hover {
    opacity: 1;
    outline: none;
  }
}
.ds-btn-accept {
  background-color: var(--yed-btn-accept-bg, "#2980B");
  color: var(--yed-btn-accept-text, white);
}
.ds-btn-cancel {
  background-color: var(--yed-btn-cancel-bg, "#2980B");
  color: var(--yed-btn-cancel-text, black);
}

/* BTN SIZES */
.btn-size {
  &--sm {
    @apply twy-text-sm;
    &.padding {
      @apply twy-px-3 twy-py-1;
    }
  }
  &--md {
    @apply twy-text-base;
    &.padding {
      @apply twy-px-5 twy-py-3;
    }
  }
  &--lg {
    @apply twy-text-lg;
    &.padding {
      @apply twy-p-6;
    }
  }
}

/* BTN VARIANTS */
// ds-btn ds-btn-back

.btn-link {
}
.btn-primary {
}
.btn-outline {
}
</style>
