<template>
  <svg
    fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="20px"
    height="20px"
  >
    <path
      d="M 13 4 C 11.906937 4 11 4.9069372 11 6 L 11 18 C 11 19.093063 11.906937 20 13 20 L 19 20 C 20.093063 20 21 19.093063 21 18 L 21 6 C 21 4.9069372 20.093063 4 19 4 L 13 4 z M 13 6 L 19 6 L 19 18 L 13 18 L 13 6 z M 7 14 L 7 18 C 7 21.309 9.691 24 13 24 L 15 24 L 15 26 L 11 26 L 11 28 L 21 28 L 21 26 L 17 26 L 17 24 L 19 24 C 22.309 24 25 21.309 25 18 L 25 14 L 23 14 L 23 18 C 23 20.206 21.206 22 19 22 L 17 22 L 13 22 C 10.794 22 9 20.206 9 18 L 9 14 L 7 14 z M 16 14 A 1 1 0 0 0 16 16 A 1 1 0 0 0 16 14 z"
    />
  </svg>
</template>
<script>
export default {
  name: "AudioIcon",
  props: {
    color: {
      type: String,
      default: "#00000"
    }
  }
};
</script>
