import Vue from "vue";
import "document-register-element/build/document-register-element";

import Chat from "vue-beautiful-chat";
import overrideBeautifulChat from "./customChat";

import axios from "axios";
import VueAxios from "vue-axios";

import vueCustomElement from "vue-custom-element";
import store from "./store/index";
import widget from "./components/chatbot/index.vue";

import "./assets/css/index.css";

Vue.use(Chat);
overrideBeautifulChat(Vue);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.use(vueCustomElement);

widget.store = store;

Vue.customElement("yedai-chatbot", widget);
