export default {
  namespaced: true,
  state: () => ({
    hasAudio: false,
    isRecording: false
  }),
  mutations: {
    SET_HAS_AUDIO(state, payload) {
      state.hasAudio = payload;
    },
    SET_RECORDING(state, payload) {
      state.isRecording = payload;
    }
  },
  actions: {
    setHasAudio({ commit }, payload) {
      commit("SET_HAS_AUDIO", payload);
    },
    setRecording({ commit }, payload) {
      commit("SET_RECORDING", payload);
    }
  },
  getters: {
    hasAudio(state) {
      return state.hasAudio;
    },
    isRecording(state) {
      return state.isRecording;
    }
  }
};
