export default {
  header: {
    bg: "var(--yed-header-bg, white)",
    text: "var(--yed-header-text, black)"
  },
  launcher: {
    bg: "var(--yed-launcher-bg, white)",
    text: "var(--yed-launcher-text, black)"
  },
  messageList: {
    bg: "var(--yed-messageList-bg, white)",
    text: "var(--yed-messageList-text, black)"
  },
  sentMessage: {
    bg: "var(--yed-sentMessage-bg, white)",
    text: "var(--yed-sentMessage-text, black)"
  },
  receivedMessage: {
    bg: "var(--yed-receivedMessage-bg, white)",
    text: "var(--yed-receivedMessage-text, black)"
  },
  userInput: {
    bg: "var(--yed-userInput-bg, white)",
    text: "var(--yed-userInput-text, black)"
  },
  userList: {
    bg: "var(--yed-userList-bg, white)",
    text: "var(--yed-userList-text, black)"
  }
};
