import VueCookie from "vue-cookie";

export default {
  namespaced: true,
  state: () => ({
    prefix: null,
    forcedLang: null,
    acceptedPrivacy: null,
    chatSession: null
  }),
  mutations: {
    SET_PREFIX(state, prefix) {
      state.prefix = prefix + "-";
    },
    SET_FORCED_LANG(state, payload) {
      state.forcedLang = payload;
    },
    SET_ACCEPTED_PRIVACY(state, payload) {
      const accept = [true, 1, "1"].includes(payload);
      const setPrivacyValue = accept ? "1" : "0";
      VueCookie.set(state.prefix + "accepted-privacy", setPrivacyValue, {
        expires: "1Y"
      });
      state.acceptedPrivacy = setPrivacyValue;
    },
    SET_CHAT_SESSION(state, payload) {
      state.chatSession = payload;
      VueCookie.set(state.prefix + "chat-session", payload, {
        expires: "1Y"
      });
    },
    REMOVE_FORCED_LANG(state) {
      VueCookie.delete(state.prefix + "forced-lang");
      state.forcedLang = null;
    },
    REMOVE_ACCEPTED_PRIVACY(state) {
      VueCookie.delete(state.prefix + "accepted-privacy");
      state.acceptedPrivacy = null;
    },
    REMOVE_CHAT_SESSION(state) {
      VueCookie.delete(state.prefix + "chat-session");
      state.chatSession = null;
    }
  },
  actions: {
    setAction({ commit }, { payload, id }) {
      // if the value is null, we remove the cookie
      if (payload === null) {
        commit(`REMOVE_${id}`, payload);
      } else {
        // if the value is NOT null, we update to the new value
        commit(`SET_${id}`, payload);
      }
    },
    setPrefix({ commit }, prefix) {
      commit("SET_PREFIX", prefix);
    },
    setForcedLang({ dispatch }, payload) {
      const id = "FORCED_LANG";
      dispatch("setAction", { payload, id });
    },
    setAcceptedPrivacy({ dispatch }, payload) {
      const id = "ACCEPTED_PRIVACY";
      dispatch("setAction", { payload, id });
    },
    setChatSession({ dispatch }, payload) {
      const id = "CHAT_SESSION";
      dispatch("setAction", { payload, id });
    },
    setStoredCookies({ dispatch }, prefix) {
      dispatch("setPrefix", prefix);

      const initialCookies = ["forcedLang", "chatSession", "acceptedPrivacy"];

      // if we have a current value stored we get it and set the store
      initialCookies.forEach(cookie => {
        const formatedCookie = cookie
          .replace(/\.?([A-Z])/g, (x, y) => `-${y.toLowerCase()}`)
          .replace(/^_/, "");
        const storedCookie = VueCookie.get(prefix + "-" + formatedCookie);
        const setActionName = `set${cookie.replace(/^\w/, c =>
          c.toUpperCase()
        )}`;
        if (storedCookie) {
          dispatch(`${setActionName}`, storedCookie);
        }
      });
    }
  },
  getters: {
    chatSession(state) {
      return state.chatSession;
    },
    forcedLang(state) {
      return state.forcedLang;
    },
    acceptedPrivacy(state) {
      return state.acceptedPrivacy;
    }
  }
};
