<template functional>
  <img
    :src="props.src"
    :data-src="props.dataSrc"
    :alt="props.alt"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
  />
</template>

<script>
export default {
  name: "CImage",
  props: {
    src: {
      type: String,
      default: ""
    },
    dataSrc: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    }
  }
};
</script>
