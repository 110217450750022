<template>
  <defs v-if="skinTone == 'black'">
    <path
      id="path-1"
      d="M5.07406251,8.18790735 C5.82668186,6.43529564 6.97726873,5.00133605 8.52582312,3.88602857 C10.8368966,2.22153586 13.4939406,1.36327229 15.648421,1.1040561 C21.3701942,0.415641262 24.7907648,3.34258498 25.2725709,4.03368655 C26.070953,5.17888388 26.4276109,6.31097788 27.1897544,7.53153526 C27.2877618,7.68849214 27.8276471,9.03978679 27.3871818,9.97541076 C27.2004916,10.3719729 26.3472543,11.3704536 24.8671148,12.0350608 C22.8551052,12.9384865 19.7114256,13.5301533 15.7570127,13.9877451 C9.85096184,13.5932713 6.41238556,12.5921906 5.04368336,9.29639698 C4.97168455,9.123026 4.99346811,8.37558612 5.07406251,8.18790735 Z"
    />
    <filter
      id="filter-2"
      x="-70.6%"
      y="-107.8%"
      width="241.3%"
      height="346.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="2"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="4"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      id="radialGradient-3"
      cx="50%"
      cy="50%"
      fx="50%"
      fy="50%"
      r="52.3937068%"
    >
      <stop stop-color="#FF9E9E" offset="0%" />
      <stop stop-color="#410202" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`front-face${avatarId}`"
      cx="66.8417096%"
      cy="19.7868013%"
      fx="66.8417096%"
      fy="19.7868013%"
      r="123.723714%"
      gradient-transform="translate(0.668417,0.197868),scale(1.000000,0.796512),rotate(122.700644),translate(-0.668417,-0.197868)"
    >
      <stop stop-color="#DA9C4F" offset="0%" />
      <stop stop-color="#CD6E3E" offset="18.1988548%" />
      <stop stop-color="#652608" offset="69.7827433%" />
      <stop stop-color="#885844" offset="100%" />
    </radialGradient>

    <radialGradient
      :id="`nose-colors${avatarId}`"
      cx="50.0014127%"
      cy="14.8127657%"
      fx="50.0014127%"
      fy="14.8127657%"
      r="108.122259%"
      gradient-transform="translate(0.500014,0.148128),scale(1.000000,0.787879),rotate(90.000000),scale(1.000000,1.265506),translate(-0.500014,-0.148128)"
    >
      <stop stop-color="#D7924B" offset="0%" />
      <stop stop-color="#C96C3C" offset="36.3510976%" />
      <stop stop-color="#AC582D" offset="99.926098%" />
    </radialGradient>

    <radialGradient
      :id="`ear-colors${avatarId}`"
      cx="0%"
      cy="72.9804337%"
      fx="0%"
      fy="72.9804337%"
      r="236.682053%"
      gradient-transform="translate(0.000000,0.729804),scale(1.000000,0.428571),rotate(-60.745515),translate(-0.000000,-0.729804)"
    >
      <stop stop-color="#C06637" offset="0%" />
      <stop stop-color="#6B2E12" offset="100%" />
    </radialGradient>

    <radialGradient
      :id="`face-highligth${avatarId}`"
      cx="63.4790306%"
      cy="43.4366988%"
      fx="63.4790306%"
      fy="43.4366988%"
      r="168.433163%"
      gradient-transform="translate(0.634790,0.434367),scale(1.000000,0.292857),rotate(114.448678),translate(-0.634790,-0.434367)"
    >
      <stop stop-color="#D7924B" offset="0%" />
      <stop stop-color="#D8964D" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`face-shadow${avatarId}`"
      cx="62.9576364%"
      cy="70.3554943%"
      fx="62.9576364%"
      fy="70.3554943%"
      r="88.4105936%"
      gradient-transform="translate(0.629576,0.703555),scale(1.000000,0.421053),rotate(148.631962),scale(1.000000,3.076240),translate(-0.629576,-0.703555)"
    >
      <stop stop-color="#000000" offset="0%" />
      <stop stop-color="#FEA46E" offset="0%" />
      <stop stop-color="#491902" offset="100%" />
    </radialGradient>

    <radialGradient
      :id="`neck-shadow${avatarId}`"
      cx="50%"
      cy="0%"
      fx="50%"
      fy="0%"
      r="100%"
      gradient-transform="translate(0.500000,0.000000),scale(0.983871,1.000000),rotate(90.000000),translate(-0.500000,-0.000000)"
    >
      <stop stop-color="#794827" offset="0%" />
      <stop stop-color="#501900" offset="100%" />
    </radialGradient>
    <linearGradient
      :id="`shadow-nose${avatarId}`"
      x1="50%"
      y1="-45.8489962%"
      x2="50%"
      y2="93.4731838%"
    >
      <stop stop-color="#B65F43" offset="0%" />
      <stop stop-color="#AB5431" offset="100%" />
    </linearGradient>

    <filter
      :id="`shadow-drop-nose${avatarId}`"
      x="-38.5%"
      y="-133.3%"
      width="176.9%"
      height="433.3%"
      filter-units="objectBoundingBox"
    >
      <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur
        std-deviation="3"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.810943161   0 0 0 0 0.197447031   0 0 0 0 0  0 0 0 1 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <linearGradient
      :id="`nose-shadow-grad${avatarId}`"
      x1="50%"
      y1="-45.8489962%"
      x2="50%"
      y2="93.4731838%"
    >
      <stop stop-color="#FFE1BC" offset="0%" />
      <stop stop-color="#C76F3A" offset="100%" />
    </linearGradient>
    <!-- <path d="M6,3 C6.22820811,3.41824406 6.88947208,3.71349588 7.56422264,3.83740699 C8.65436259,4.01035242 10.1774127,4.41752873 12.8546848,4.45981999 C16.128859,4.45981999 16.5151451,5.33171745 18.3489536,5.33171745 L18.9699342,5.33171745 L18.9699342,5.33171745 L18.9699342,9 L18.9699342,9 C14.5030158,8.83499923 16.6258323,7.26942064 8.33466195,6.28255562 C6.93215004,5.78395885 6,5.41963278 6,3 Z M32,3 C32,5.41974114 31.0693582,5.78408352 29.6691156,6.28270262 C21.3913603,7.26961183 23.4806695,8.83499185 19.0209786,9 L19.0209786,9 L19.0209786,5.33182186 L19.0209786,5.33182186 L19.6710271,5.33182186 C21.5018684,5.33182186 21.8875296,4.45988537 25.1564061,4.45988537 C27.8293464,4.41759221 29.3499322,4.01039767 30.4383083,3.83744449 C31.1119671,3.71352784 31.7721611,3.41826279 32,3 Z" id="path-2"></path> -->
    <filter
      :id="`nose-shadow-drop${avatarId}`"
      x="-38.5%"
      y="-133.3%"
      width="176.9%"
      height="433.3%"
      filter-units="objectBoundingBox"
    >
      <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur
        std-deviation="3"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.810943161   0 0 0 0 0.197447031   0 0 0 0 0  0 0 0 1 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <filter
      :id="`eye-shadow${avatarId}`"
      x="-95.5%"
      y="-83.3%"
      width="290.9%"
      height="333.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="6"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="5"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feComposite
        in="shadowBlurOuter1"
        in2="SourceAlpha"
        operator="out"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.419582201   0 0 0 0 0.134266304   0 0 0 0 0  0 0 0 0.529938811 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
  </defs>

  <defs v-else-if="skinTone == 'white'">
    <path
      id="path-1"
      d="M5.07406251,8.18790735 C5.82668186,6.43529564 6.97726873,5.00133605 8.52582312,3.88602857 C10.8368966,2.22153586 13.4939406,1.36327229 15.648421,1.1040561 C21.3701942,0.415641262 24.7907648,3.34258498 25.2725709,4.03368655 C26.070953,5.17888388 26.4276109,6.31097788 27.1897544,7.53153526 C27.2877618,7.68849214 27.8276471,9.03978679 27.3871818,9.97541076 C27.2004916,10.3719729 26.3472543,11.3704536 24.8671148,12.0350608 C22.8551052,12.9384865 19.7114256,13.5301533 15.7570127,13.9877451 C9.85096184,13.5932713 6.41238556,12.5921906 5.04368336,9.29639698 C4.97168455,9.123026 4.99346811,8.37558612 5.07406251,8.18790735 Z"
    />
    <filter
      id="filter-2"
      x="-70.6%"
      y="-107.8%"
      width="241.3%"
      height="346.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="2"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="4"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      id="radialGradient-3"
      cx="50%"
      cy="50%"
      fx="50%"
      fy="50%"
      r="52.3937068%"
    >
      <stop stop-color="#FF9E9E" offset="0%" />
      <stop stop-color="#410202" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`front-face${avatarId}`"
      cx="66.8417096%"
      cy="19.7868013%"
      fx="66.8417096%"
      fy="19.7868013%"
      r="123.987951%"
      gradient-transform="translate(0.668417,0.197868),scale(1.000000,0.794118),rotate(122.622286),translate(-0.668417,-0.197868)"
    >
      <stop stop-color="#FFFCF7" offset="0%" />
      <stop stop-color="#FFD9BA" offset="17.1848606%" />
      <stop stop-color="#FCC68A" offset="69.7827433%" />
      <stop stop-color="#FFAD44" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`nose-colors${avatarId}`"
      cx="50.0014127%"
      cy="14.8127657%"
      fx="50.0014127%"
      fy="14.8127657%"
      r="108.122259%"
      gradient-transform="translate(0.500014,0.148128),scale(1.000000,0.787879),rotate(90.000000),scale(1.000000,1.265506),translate(-0.500014,-0.148128)"
    >
      <stop stop-color="#FCD3D3" offset="0%" />
      <stop stop-color="#FFF8F1" offset="23.9627323%" />
      <stop stop-color="#FFECD6" offset="38.0384291%" />
      <stop stop-color="#FED2A9" offset="99.926098%" />
    </radialGradient>
    <filter
      :id="`nose-shadow${avatarId}`"
      x="-13.5%"
      y="-41.7%"
      width="126.9%"
      height="216.7%"
      filter-units="objectBoundingBox"
    >
      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur
        std-deviation="1"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.565245697   0 0 0 0 0.232552262   0 0 0 0 0.125478513  0 0 0 1 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      :id="`ear-colors${avatarId}`"
      cx="0%"
      cy="72.9804337%"
      fx="0%"
      fy="72.9804337%"
      r="236.682053%"
      gradient-transform="translate(0.000000,0.729804),scale(1.000000,0.428571),rotate(-60.745515),translate(-0.000000,-0.729804)"
    >
      <stop stop-color="#FEAF6E" offset="0%" />
      <stop stop-color="#F0956A" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`face-highligth${avatarId}`"
      cx="63.4790306%"
      cy="43.4366988%"
      fx="63.4790306%"
      fy="43.4366988%"
      r="168.433163%"
      gradient-transform="translate(0.634790,0.434367),scale(1.000000,0.292857),rotate(114.448678),translate(-0.634790,-0.434367)"
    >
      <stop stop-color="#FECC9A" offset="0%" />
      <stop stop-color="#FFF4EA" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`face-shadow${avatarId}`"
      cx="62.9576364%"
      cy="70.3554943%"
      fx="62.9576364%"
      fy="70.3554943%"
      r="88.4105936%"
      gradient-transform="translate(0.629576,0.703555),scale(1.000000,0.421053),rotate(148.631962),scale(1.000000,3.076240),translate(-0.629576,-0.703555)"
    >
      <stop stop-color="#000000" offset="0%" />
      <stop stop-color="#FEA46E" offset="0%" />
      <stop stop-color="#491902" offset="100%" />
    </radialGradient>
    <linearGradient
      :id="`nose-shadow-grad${avatarId}`"
      x1="50%"
      y1="-45.8489962%"
      x2="50%"
      y2="93.4731838%"
    >
      <stop stop-color="#FFE1BC" offset="0%" />
      <stop stop-color="#C76F3A" offset="100%" />
    </linearGradient>
    <!-- <path d="M6,3 C6.22820811,3.41824406 6.88947208,3.71349588 7.56422264,3.83740699 C8.65436259,4.01035242 10.1774127,4.41752873 12.8546848,4.45981999 C16.128859,4.45981999 16.5151451,5.33171745 18.3489536,5.33171745 L18.9699342,5.33171745 L18.9699342,5.33171745 L18.9699342,9 L18.9699342,9 C14.5030158,8.83499923 16.6258323,7.26942064 8.33466195,6.28255562 C6.93215004,5.78395885 6,5.41963278 6,3 Z M32,3 C32,5.41974114 31.0693582,5.78408352 29.6691156,6.28270262 C21.3913603,7.26961183 23.4806695,8.83499185 19.0209786,9 L19.0209786,9 L19.0209786,5.33182186 L19.0209786,5.33182186 L19.6710271,5.33182186 C21.5018684,5.33182186 21.8875296,4.45988537 25.1564061,4.45988537 C27.8293464,4.41759221 29.3499322,4.01039767 30.4383083,3.83744449 C31.1119671,3.71352784 31.7721611,3.41826279 32,3 Z" id="path-2"></path> -->
    <filter
      :id="`nose-shadow-drop${avatarId}`"
      x="-38.5%"
      y="-133.3%"
      width="176.9%"
      height="433.3%"
      filter-units="objectBoundingBox"
    >
      <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur
        std-deviation="3"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.810943161   0 0 0 0 0.197447031   0 0 0 0 0  0 0 0 1 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      :id="`neck-shadow${avatarId}`"
      cx="50%"
      cy="0%"
      fx="50%"
      fy="0%"
      r="100%"
      gradient-transform="translate(0.500000,0.000000),scale(0.983871,1.000000),rotate(90.000000),translate(-0.500000,-0.000000)"
    >
      <stop stop-color="red" offset="0%" />
      <stop stop-color="#A24E1F" offset="100%" />
    </radialGradient>
    <filter
      :id="`eye-shadow${avatarId}`"
      x="-95.5%"
      y="-83.3%"
      width="290.9%"
      height="333.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="6"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="5"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feComposite
        in="shadowBlurOuter1"
        in2="SourceAlpha"
        operator="out"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.419582201   0 0 0 0 0.134266304   0 0 0 0 0  0 0 0 0.529938811 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
  </defs>
  <defs v-else>
    <path
      id="path-1"
      d="M5.07406251,8.18790735 C5.82668186,6.43529564 6.97726873,5.00133605 8.52582312,3.88602857 C10.8368966,2.22153586 13.4939406,1.36327229 15.648421,1.1040561 C21.3701942,0.415641262 24.7907648,3.34258498 25.2725709,4.03368655 C26.070953,5.17888388 26.4276109,6.31097788 27.1897544,7.53153526 C27.2877618,7.68849214 27.8276471,9.03978679 27.3871818,9.97541076 C27.2004916,10.3719729 26.3472543,11.3704536 24.8671148,12.0350608 C22.8551052,12.9384865 19.7114256,13.5301533 15.7570127,13.9877451 C9.85096184,13.5932713 6.41238556,12.5921906 5.04368336,9.29639698 C4.97168455,9.123026 4.99346811,8.37558612 5.07406251,8.18790735 Z"
    />
    <filter
      id="filter-2"
      x="-70.6%"
      y="-107.8%"
      width="241.3%"
      height="346.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="2"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="4"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      id="radialGradient-3"
      cx="50%"
      cy="50%"
      fx="50%"
      fy="50%"
      r="52.3937068%"
    >
      <stop stop-color="#FF9E9E" offset="0%" />
      <stop stop-color="#410202" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`front-face${avatarId}`"
      cx="66.8417096%"
      cy="19.7868013%"
      fx="66.8417096%"
      fy="19.7868013%"
      r="123.987951%"
      gradient-transform="translate(0.668417,0.197868),scale(1.000000,0.794118),rotate(122.622286),translate(-0.668417,-0.197868)"
    >
      <stop stop-color="#FFC680" offset="0%" />
      <stop stop-color="#FEB56E" offset="18.1988548%" />
      <stop stop-color="#FF9E6F" offset="69.7827433%" />
      <stop stop-color="#885844" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`nose-colors${avatarId}`"
      cx="50.0014127%"
      cy="14.8127657%"
      fx="50.0014127%"
      fy="14.8127657%"
      r="108.122259%"
      gradient-transform="translate(0.500014,0.148128),scale(1.000000,0.787879),rotate(90.000000),scale(1.000000,1.265506),translate(-0.500014,-0.148128)"
    >
      <stop stop-color="#FFA1A1" offset="0%" />
      <stop stop-color="#FFD6A5" offset="15.3214961%" />
      <stop stop-color="#FFC37D" offset="36.3510976%" />
      <stop stop-color="#FFA86F" offset="99.926098%" />
    </radialGradient>
    <linearGradient
      :id="`nose-shadow-grad${avatarId}`"
      x1="50%"
      y1="-45.8489962%"
      x2="50%"
      y2="93.4731838%"
    >
      <stop stop-color="#FFE1BC" offset="0%" />
      <stop stop-color="#C76F3A" offset="100%" />
    </linearGradient>
    <!-- <path d="M6,3 C6.22820811,3.41824406 6.88947208,3.71349588 7.56422264,3.83740699 C8.65436259,4.01035242 10.1774127,4.41752873 12.8546848,4.45981999 C16.128859,4.45981999 16.5151451,5.33171745 18.3489536,5.33171745 L18.9699342,5.33171745 L18.9699342,5.33171745 L18.9699342,9 L18.9699342,9 C14.5030158,8.83499923 16.6258323,7.26942064 8.33466195,6.28255562 C6.93215004,5.78395885 6,5.41963278 6,3 Z M32,3 C32,5.41974114 31.0693582,5.78408352 29.6691156,6.28270262 C21.3913603,7.26961183 23.4806695,8.83499185 19.0209786,9 L19.0209786,9 L19.0209786,5.33182186 L19.0209786,5.33182186 L19.6710271,5.33182186 C21.5018684,5.33182186 21.8875296,4.45988537 25.1564061,4.45988537 C27.8293464,4.41759221 29.3499322,4.01039767 30.4383083,3.83744449 C31.1119671,3.71352784 31.7721611,3.41826279 32,3 Z" id="path-2"></path> -->
    <filter
      :id="`nose-shadow-drop${avatarId}`"
      x="-38.5%"
      y="-133.3%"
      width="176.9%"
      height="433.3%"
      filter-units="objectBoundingBox"
    >
      <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur
        std-deviation="3"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.810943161   0 0 0 0 0.197447031   0 0 0 0 0  0 0 0 1 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <radialGradient
      :id="`ear-colors${avatarId}`"
      cx="0%"
      cy="72.9804337%"
      fx="0%"
      fy="72.9804337%"
      r="236.682053%"
      gradient-transform="translate(0.000000,0.729804),scale(1.000000,0.428571),rotate(-60.745515),translate(-0.000000,-0.729804)"
    >
      <stop stop-color="#FEAF6E" offset="0%" />
      <stop stop-color="#F0956A" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`face-highligth${avatarId}`"
      cx="63.4790306%"
      cy="43.4366988%"
      fx="63.4790306%"
      fy="43.4366988%"
      r="168.433163%"
      gradient-transform="translate(0.634790,0.434367),scale(1.000000,0.292857),rotate(114.448678),translate(-0.634790,-0.434367)"
    >
      <stop stop-color="#FECC9A" offset="0%" />
      <stop stop-color="#FFF4EA" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`face-shadow${avatarId}`"
      cx="62.9576364%"
      cy="70.3554943%"
      fx="62.9576364%"
      fy="70.3554943%"
      r="88.4105936%"
      gradient-transform="translate(0.629576,0.703555),scale(1.000000,0.421053),rotate(148.631962),scale(1.000000,3.076240),translate(-0.629576,-0.703555)"
    >
      <stop stop-color="#000000" offset="0%" />
      <stop stop-color="#FEA46E" offset="0%" />
      <stop stop-color="#491902" offset="100%" />
    </radialGradient>
    <radialGradient
      :id="`neck-shadow${avatarId}`"
      cx="50%"
      cy="0%"
      fx="50%"
      fy="0%"
      r="100%"
      gradient-transform="translate(0.500000,0.000000),scale(0.983871,1.000000),rotate(90.000000),translate(-0.500000,-0.000000)"
    >
      <stop stop-color="red" offset="0%" />
      <stop stop-color="#A24E1F" offset="100%" />
    </radialGradient>
    <filter
      :id="`eye-shadow${avatarId}`"
      x="-95.5%"
      y="-83.3%"
      width="290.9%"
      height="333.3%"
      filter-units="objectBoundingBox"
    >
      <feMorphology
        radius="1"
        operator="dilate"
        in="SourceAlpha"
        result="shadowSpreadOuter1"
      />
      <feOffset
        dx="0"
        dy="6"
        in="shadowSpreadOuter1"
        result="shadowOffsetOuter1"
      />
      <feGaussianBlur
        std-deviation="5"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />
      <feComposite
        in="shadowBlurOuter1"
        in2="SourceAlpha"
        operator="out"
        result="shadowBlurOuter1"
      />
      <feColorMatrix
        values="0 0 0 0 0.419582201   0 0 0 0 0.134266304   0 0 0 0 0  0 0 0 0.529938811 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
  </defs>
</template>

<script>
export default {
  props: {
    skinTone: {
      type: String,
      default: "white"
    },
    avatarId: {
      type: String,
      default: ""
    }
  }
};
</script>
