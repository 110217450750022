<template>
  <div
    class="twy-absolute twy-inset-0 twy-z-20 yed-legal twy-rounded-2xl twy-p-4 twy-flex twy-justify-center twy-items-center"
  >
    <div class="twy-w-full">
      <h4
        v-html="$trans.title"
        class="twy-text-lg twy-text-bold twy-mb-2"
        id="widget-title"
      ></h4>
      <div v-html="$trans.text" id="widget-message"></div>
      <CButton
        v-html="$trans.link_title"
        variant="link"
        :href="urlLink"
        id="widget-link"
      >
      </CButton>
      <div class="twy-mt-8 twy-flex twy-justify-center twy-items-center">
        <CButton
          variant="cancel"
          @click="acceptCookies(false)"
          class="twy-w-full twy-rounded-xl twy-justify-center twy-pb-0 hover:twy-underline hover:twy-shadow-none"
          v-html="$trans.reject"
        >
        </CButton>
        <CButton
          variant="accept"
          @click="acceptCookies(true)"
          class="twy-w-full twy-rounded-xl twy-pb-0 twy-justify-center hover:twy-underline"
          v-html="$trans.confirm"
        >
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CButton from "@/components/common/c-button/index.vue";

export default {
  components: {
    CButton
  },
  props: {
    policyUrl: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters("i18n", ["trans"]),
    $trans() {
      return this.trans?.cookies;
    },
    urlLink() {
      return this.policyUrl || this.$trans.link || null;
    }
  },
  methods: {
    ...mapActions("cookies", ["setAcceptedPrivacy"]),
    acceptCookies(value) {
      this.setAcceptedPrivacy(value);
      this.$emit("accept", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.yed-legal {
  --yed-legal-bg: var(--yed-messages-bg);
  --yed-legal-text: var(--yed-messages-text);
  background: var(--yed-legal-bg, white);
  color: var(--yed-legal-text, black);
}
</style>
