export default [
  {
    id: "mattmezza",
    name: "Matteo",
    imageUrl: "https://avatars3.githubusercontent.com/u/1915989?s=230&v=4"
  },
  {
    id: "support",
    name: "Support",
    imageUrl: "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4"
  }
];
