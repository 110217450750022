<template>
  <div class="chat-modal twy-shadow twy-p-4">
    <p class="twy-text-left twy-text-sm" v-html="$trans.text" />
    <div class="twy-mt-4 twy-flex twy-flex-wrap twy-justify-around">
      <CButton variant="cancel" @click="clearHistoy()">
        <span v-html="$trans.confirm"></span>
      </CButton>
      <CButton variant="accept" @click="closeClearConfirmation()">
        <span v-html="$trans.reject"></span>
      </CButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CButton from "@/components/common/c-button/index.vue";

export default {
  components: {
    CButton
  },
  computed: {
    ...mapGetters("i18n", ["trans"]),
    $trans() {
      return this.trans?.removeConversation;
    }
  },
  methods: {
    clearHistoy() {
      this.$emit("close", true);
    },
    closeClearConfirmation() {
      this.$emit("close");
    }
  }
};
</script>
