<template>
  <div
    class="twy-absolute twy-inset-0 twy-bg-black twy-bg-opacity-70 twy-z-20  yed-legal twy-rounded-2xl twy-p-4 twy-flex twy-justify-center twy-items-center"
  >
    <div
      class="twy-z-21 twy-w-full twy-bg-white twy-text-black tw-y-1/2 twy-border-2 twy-rounded-md twy-border-black-500 twy-p-4"
    >
      <p v-html="messageText"></p>

      <input
        class="twy-w-full twy-mt-2 twy-p-1 twy-border"
        placeholder="mail@email.com"
        :type="type"
        name="userEmail"
        id="userEmail"
        v-on:keyup.enter="onClick()"
        v-model.lazy="mail"
      />
      <p
        class="twy-text-red-500 twy-text-sm twy-mt-2 twy-text-center"
        v-if="error"
      >
        <span v-html="error"></span>
      </p>
      <div class="twy-mt-4 chat twy-flex twy-flex-wrap twy-justify-around">
        <CButton
          variant="accept"
          @click="onClick()"
          class="twy-w-1/3 twy-justify-center"
          v-html="acceptText"
        >
        </CButton>
        <CButton
          variant="cancel"
          @click="$emit('cancel')"
          class="twy-w-1/3 twy-justify-center"
          v-html="cancelText"
        >
        </CButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CButton from "../../common/c-button/index.vue";
export default {
  components: { CButton },
  props: {
    type: {
      type: String,
      default: "email"
    }
  },
  data() {
    return {
      mail: "",
      error: "",
      acceptText: "",
      cancelText: "",
      messageText: ""
    };
  },
  computed: {
    ...mapGetters("i18n", ["transLang"])
  },
  methods: {
    setMessage(message, lang) {
      this.lang = lang;
      this.acceptText = this.transLang(lang)?.accept?.title || "Accept";
      this.cancelText = this.transLang(lang)?.cancel?.title || "Cancel";
      this.messageText = message;
    },
    onClick() {
      if (!this.mail || !this.validEmail(this.mail)) {
        this.error =
          this.transLang(this.lang)?.email_required?.title ||
          "Valid email required.";
      } else this.$emit("accept", this.mail);
    },
    validEmail(mail) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(mail);
    }
  }
};
</script>
