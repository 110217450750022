<template>
  <div
    class="chat-menu twy-flex twy-justify-center twy-items-center twy-flex-grow twy-relative"
  >
    <div class="twy-text-lg twy-font-bold chat_title">
      {{ title }}
    </div>

    <CButton
      v-if="showClearConfirmation"
      variant="custom"
      class="twy-ml-auto twy-my-auto twy-mr-0 twy-p-2"
      type="button"
      @click="toggleMenu()"
    >
      <MenuIcon />
    </CButton>
    <CButton
      variant="custom"
      :class="toggleMenuSytle"
      class="twy-my-auto twy-mr-0 twy-p-2"
      type="button"
      @click="closeChat()"
    >
      <CloseIcon />
    </CButton>
    <transition name="fade">
      <div v-if="showMenu" class="chat-submenu twy-shadow">
        <CButton @click="clearConfirmation()" type="button">
          <span v-html="clearConversation"></span>
        </CButton>
      </div>
    </transition>
    <transition name="fade">
      <MenuConfirmation v-if="showWarning" @close="clearHistory" />
    </transition>
    <div v-if="showMenu || showWarning" class="coverbg" @click="closeMenu()" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CButton from "@/components/common/c-button/index.vue";
import MenuIcon from "./menu-icon.vue";
import CloseIcon from "./close-icon.vue";
import MenuConfirmation from "./menu-confirmation.vue";
import { removeLocalStorage } from "../utils/localStorage.js";

const none = "none";
const simple = "simple";
const full = "full";

export const menuTypes = {
  none,
  simple,
  full
};

const menuStylesOptions = {
  [menuTypes.none]: {
    name: menuTypes.none,
    answerLang: false,
    uiLang: false
  },
  [menuTypes.simple]: {
    name: menuTypes.simple,
    answerLang: true,
    uiLang: false
  },
  [menuTypes.full]: {
    name: menuTypes.full,
    answerLang: true,
    uiLang: true
  }
};
const menuStyles = Object.keys(menuStylesOptions);

export default {
  components: {
    CButton,
    MenuIcon,
    CloseIcon,
    MenuConfirmation
  },
  props: {
    showClearCookies: {
      type: Boolean,
      default: false
    },
    showClearConfirmation: {
      type: Boolean,
      default: true
    },
    endPoint: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    languageMenu: {
      type: String,
      required: true,
      validator: value => menuStyles.includes(value)
    }
  },
  data() {
    return {
      showMenu: false,
      showWarning: false,
      isForcedLang: false,
      menuTypes
    };
  },
  watch: {
    isForcedLang(newValue) {
      if (newValue) {
        this.setForcedLang(newValue);
      } else {
        this.setForcedLang(null);
      }
    }
  },
  computed: {
    ...mapGetters("i18n", ["trans"]),
    ...mapGetters("cookies", ["chatSession"]),
    clearConversation() {
      return this.trans?.removeConversation?.title;
    },
    $transForce() {
      return this.trans?.forceLang;
    },
    langHeader() {
      return this.trans?.langHeader?.title;
    },
    menuType() {
      return menuStylesOptions[this.languageMenu];
    },
    toggleMenuSytle() {
      return this.showClearConfirmation ? "twy-ml-1" : "twy-ml-auto";
    }
  },
  mounted() {
    this.setForcedLang(null);
  },
  methods: {
    ...mapActions("i18n", [
      "setCurrentLang" // -> this.someGetter
    ]),
    ...mapActions("cookies", [
      "setForcedLang",
      "setAcceptedPrivacy",
      "setChatSession"
    ]),
    closeChat() {
      this.$emit("closeChat");
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
      this.showWarning = false;
    },
    clearConfirmation() {
      this.showMenu = false;
      this.showWarning = true;
    },
    clearHistory(value) {
      this.showWarning = false;
      if (!value) {
        return;
      }
      this.axios
        .delete(this.endPoint, {
          data: {
            session_uid: this.chatSession
          }
        })
        .then(() => {
          this.setForcedLang(null);
          this.setAcceptedPrivacy(null);
          removeLocalStorage("messageList");
          window.location.reload();
        });
    },
    closeClearConfirmation() {
      this.showWarning = false;
      this.showMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.coverbg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat-modal,
.chat-submenu {
  min-width: 150px;
  text-align: center;
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--yed-messageList-bg, white);
  color: var(--yed-messageList-text, white);
}
</style>
