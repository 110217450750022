<template>
  <chatbot
    ref="chatbot"
    class="yedai"
    :end-point="endPoint"
    :title-header="titleHeader"
    :backgroundHeaderImage="backgroundHeaderImage"
    :lang="lang"
    :language-menu="languageMenu"
    :placeholder-text="placeholder"
    :style="computedStyle"
    :policy-url="policyUrl"
    :force-reload="forceReload"
    :store-autoaccept="storeAutoaccept"
    :hide-close-bubble="hideCloseBubble"
    :bubble-with-image="bubbleWithImage"
    :cookie-prefix="cookiePrefix"
    :zIndex="zIndex"
  >
    <template #avatarBot>
      <avatar variant="bot" :avatar="avatarImgBot" />
    </template>
    <template #avatarUser>
      <avatar variant="user" :avatar="avatarImgUser" class="twy-mt-auto" />
    </template>
    <template v-if="introText" #callToAction>
      <!-- call to action has to have a tag -->
      <div class="yed-bubble" @click="openChat()">
        {{ introText }}
      </div>
    </template>
    <template #errorMessage>
      <!-- call to action has to have a tag -->
      <div class="yed-bubble">we are sorry, currently not available</div>
    </template>
  </chatbot>
</template>

<script>
import chatbot from "./chat";
import avatar from "./avatar/index";
import { menuTypes } from "@/components/chatbot/menu/index.vue";

export default {
  components: {
    chatbot,
    avatar
  },
  props: {
    open: {
      type: Boolean,
      default: true
    },
    endPoint: {
      type: String,
      default: ""
    },
    policyUrl: {
      type: String,
      default: ""
    },
    forceReload: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: null
    },
    languageMenu: {
      type: String,
      default: menuTypes.simple
    },
    introText: {
      type: String,
      default: ""
    },
    titleHeader: {
      type: String,
      default: ""
    },
    bubbleImage: {
      type: String,
      default: "default"
    },
    avatarImgBot: {
      type: String,
      default: "default"
    },
    avatarImgUser: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: "Write something..."
    },
    storeAutoaccept: {
      type: Boolean,
      default: false
    },
    bubbleWithAvatar: {
      type: Boolean,
      default: true
    },
    hideCloseBubble: {
      type: Boolean,
      default: true
    },
    cookiePrefix: {
      type: String,
      default: "yedai"
    },
    zIndex: {
      type: Number,
      default: 100
    },
    backgroundHeaderImage: {
      type: String,
      default: ""
    }
  },
  computed: {
    bubbleImageSet() {
      return !!this.bubbleImage && this.bubbleImage !== "default";
    },
    avatarImgBotSet() {
      return !!this.avatarImgBot && this.avatarImgBot !== "default";
    },
    bubbleWithImage() {
      return (
        this.bubbleImageSet || (this.bubbleWithAvatar && this.avatarImgBotSet)
      );
    },
    computedStyle() {
      if (this.bubbleImageSet) {
        return `--yed-bubble-avatar: url(${this.bubbleImage})`;
      }
      if (this.avatarImgBotSet) {
        return `--yed-bubble-avatar: url(${this.avatarImgBot})`;
      }
      return "";
    }
  },
  watch: {
    open() {
      this.toggleChat();
    }
  },
  mounted() {
    const { paramOpen } = this.readConfigFromUrl();
    if (this.open || paramOpen) this.openChat();
  },
  methods: {
    openChat() {
      this.$refs.chatbot.openChat();
    },
    closeChat() {
      this.$refs.chatbot.closeChat();
    },
    toggleChat() {
      const isOpen = this.$refs.chatbot.isChatOpen;
      isOpen ? this.closeChat() : this.openChat();
    },
    readConfigFromUrl() {
      const queryParams = new URLSearchParams(window.location.search);
      return {
        paramOpen: queryParams.get("chatbot") === "open" ? true : false
      };
    }
  }
};
</script>

<style lang="scss">
.yed-bubble {
  @apply twy-rounded-2xl twy-absolute twy-px-4 twy-py-2 twy-rounded-tr-none twy-text-sm twy-border-gray-300;
  box-shadow: 0 6px 20px 4px rgba(148, 149, 150, 0.2);
  bottom: 0;
  right: 0;
  background-color: var(--yed-cta-bg, white);
  color: var(--yed-cta-text, black);
  cursor: pointer;
}
// Animations
.fade-enter-active {
  animation: fade-down 0.5s;
}
.fade-leave-active {
  animation: fade-down 0.1s reverse;
}
@keyframes fade-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: stranslateY(0);
    opacity: 1;
  }
}
</style>
