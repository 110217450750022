<template>
  <svg :class="`icon icons_${activeIcon} ${active ? 'active' : ''}`">
    <use :xlink:href="`#${activeIcon}`">
      <!-- <path d="M13.6689 8.56604H12.4335C12.5594 8.91081 12.6282 9.28289 12.6282 9.67069V14.3399C12.6282 14.5016 12.6001 14.6568 12.5489 14.8012H14.5913C15.3543 14.8012 15.975 14.1804 15.975 13.4175V10.8721C15.975 9.60055 14.9405 8.56604 13.6689 8.56604Z" /><path d="M3.34679 9.67068C3.34679 9.28285 3.41559 8.91081 3.54152 8.56604H2.30608C1.0345 8.56604 0 9.60054 0 10.8721V13.4175C0 14.1805 0.620684 14.8012 1.38365 14.8012H3.42613C3.37493 14.6568 3.34679 14.5016 3.34679 14.3399V9.67068Z" /><path d="M9.39975 7.36462H6.57536C5.30379 7.36462 4.26929 8.39913 4.26929 9.6707V14.34C4.26929 14.5946 4.47578 14.8012 4.7305 14.8012H11.2446C11.4993 14.8012 11.7058 14.5947 11.7058 14.34V9.6707C11.7058 8.39913 10.6713 7.36462 9.39975 7.36462Z" /><path d="M7.98757 1.25C6.45835 1.25 5.21423 2.49411 5.21423 4.02338C5.21423 5.06066 5.78674 5.96658 6.63223 6.44221C7.03326 6.6678 7.49559 6.79672 7.98757 6.79672C8.47955 6.79672 8.94189 6.6678 9.34292 6.44221C10.1884 5.96658 10.7609 5.06063 10.7609 4.02338C10.7609 2.49415 9.5168 1.25 7.98757 1.25Z" /><path d="M3.11767 3.83496C1.974 3.83496 1.04358 4.76538 1.04358 5.90906C1.04358 7.05273 1.974 7.98315 3.11767 7.98315C3.40778 7.98315 3.68404 7.92306 3.93499 7.81504C4.36887 7.62824 4.72662 7.29757 4.948 6.88337C5.10338 6.59267 5.19177 6.26104 5.19177 5.90906C5.19177 4.76541 4.26135 3.83496 3.11767 3.83496Z" /><path d="M12.8574 3.83496C11.7137 3.83496 10.7833 4.76538 10.7833 5.90906C10.7833 6.26107 10.8717 6.5927 11.0271 6.88337C11.2485 7.2976 11.6062 7.62827 12.0401 7.81504C12.2911 7.92306 12.5673 7.98315 12.8574 7.98315C14.0011 7.98315 14.9315 7.05273 14.9315 5.90906C14.9315 4.76538 14.0011 3.83496 12.8574 3.83496Z" /> -->
    </use>
  </svg>
</template>

<script>
export default {
  name: "CIcons",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      activeIcon: "user"
    };
  },
  mounted() {
    const defaultIcon = "file";
    let imgList = null;
    try {
      imgList =
        document.getElementById("icons-list-chat") ||
        document.getElementById("icons-list");
    } catch (e) {
      console.log("you have to add icons list on your project");
    }
    const existIcon = imgList && imgList.innerHTML.includes(`id="${this.icon}`);

    this.activeIcon = this.icon;
    if (!existIcon) {
      this.activeIcon = defaultIcon;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
}

.icons_logo {
  width: 75px;
  height: 75px;
}

.icon-step {
  width: 96px;
  height: 88px;
}
</style>
