<template>
  <div v-if="avatar" class="yed-avatar" :style="{ backgroundColor }">
    <avatar v-if="defaultAvatar" :avatar-attributes="avatarAttrs" />
    <img
      v-else-if="isImg"
      :src="avatar"
      :alt="variant"
      class="twy-relative twy-z-10"
    />
    <template v-else>
      {{ avatar }}
    </template>
  </div>
</template>

<script>
import avatar from "./avatar/avatar.vue";

export default {
  components: {
    avatar
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: "bot",
      validator(value) {
        return ["bot", "user"].includes(value);
      }
    },
    // if img prop is default we use default avatar, otherwise use img link provided
    avatar: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      backgroundColor: `var(--yed-${this.variant}-bg)`,
      avatarAttrs: null
    };
  },
  computed: {
    isImg() {
      return this.avatar.match(/\.(jpeg|jpg|gif|png|svg)/) != null;
    },
    defaultAvatar() {
      return this.avatar === "default";
    }
  },
  mounted() {
    if (this.defaultAvatar) {
      this.avatarAttrs = {
        skinTone: "white",
        hairStyle: "black",
        hairColor: "black",
        mask: false
      };
    }
  },
  methods: {
    createAvatar() {
      // default skinOptions ["black", "white", "rose"]
      const skinOptions = ["black", "white", "rose"];
      this.avatar.skinTone = this.randomItemList(skinOptions);

      // default hairStyleOptions ["long", "short", "long", "half-bald", "bald"]
      const hairStyleOptions = ["long", "short", "long", "half-bald", "bald"];
      this.avatar.hairStyle = this.randomItemList(hairStyleOptions);

      // default hairColorOptions ["brown", "black", "gray", "white", "blond"]
      const hairColorOptions = ["brown", "black", "gray", "white", "blond"];
      this.avatar.hairColor = this.randomItemList(hairColorOptions);

      // maskChange based on 100% (0-100)
      const maskChange = 0;
      this.avatar.mask = this.randomOption(maskChange);
    },
    randomItemList(options) {
      const randomOption = Math.floor(Math.random() * options.length);
      return options[randomOption];
    },
    randomOption(chances) {
      // chances base in 100%
      const randomPercent = Math.floor(Math.random() * 100);
      if (isNaN(chances)) {
        console.log("not number");
        return;
      }
      return randomPercent <= chances;
    }
  }
};
</script>

<style lang="scss" scoped>
.yed-avatar {
  @apply twy-w-10 twy-h-10 twy-rounded-full twy-mx-2 twy-relative twy-flex twy-items-center twy-justify-center twy-flex-shrink-0;
  &:before {
    content: "";
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--yed-messageList-bg);
    opacity: 0.25;
  }
}
</style>
