import CustomUserInput from "./components/chatbot/audio/UserInput.vue";

/**
 * Override vue beatiful chat.
 * The base library doesn't allow to inject your own components, so you need
 * to override and redifine the Launcher component with your own ones.
 *
 * At least we need to override the UserInput.
 */
export default function overrideBeautifulChat(Vue) {
  const Launcher = Vue.component("BeautifulChat");
  const ChatWindow = Launcher.options.components.ChatWindow;
  const UserInput = ChatWindow.components.UserInput;

  Vue.component("BeautifulChat", {
    extends: Launcher,
    components: {
      ChatWindow: {
        extends: ChatWindow,
        components: {
          UserInput: {
            components: UserInput.components,
            mixins: [UserInput, CustomUserInput]
          }
        }
      }
    }
  });
}
