<template>
  <component v-if="mappedComponent" :is="mappedComponent" :message="message" />
  <p v-else>Beep Boop Error! 🤖</p>
</template>

<script>
import MessageText from "./message-text.vue";
import MessageCards from "./message-cards.vue";

export default {
  props: {
    message: {
      type: Object,
      default: () => null
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mime() {
      return this.message.data.mime;
    },
    author() {
      return this.message.author;
    },
    isUserMessage() {
      return this.author === "me";
    },
    mappedComponent() {
      if (this.mime === "text" || this.isUserMessage) {
        return MessageText;
      }

      if (this.mime?.startsWith("card/") && this.open) {
        return MessageCards;
      }

      return null;
    }
  },
  watch: {
    open() {
      if (this.open && this.$refs.swiper) {
        this.$refs.swiper.updateSwiper();
      }
    }
  }
};
</script>
