<template>
  <g
    v-if="hairStyle == 'long'"
    transform="translate(118.500000, 121.000000) scale(-1, 1) translate(-118.500000, -121.000000) translate(37.000000, 28.000000)"
    class="avatar__hair"
  >
    <path
      id="Oval-Copy-251"
      d="M82.6417302,225 C127.611233,225 164.066218,182.860153 164.066218,130.878017 C164.066218,114.726076 167.468433,33.5635344 161.284636,17.4400722 C147.566986,-18.3269499 113.768129,15.4111637 82.7715939,15.4111637 C61.8102136,15.4111637 29.2139208,-2.55242597 14.7520355,17.4400722 C-1.8118888,40.3384855 1.2172427,103.12602 1.2172427,130.878017 C1.2172427,161.354191 13.7477766,188.447313 33.1782708,205.648765 C46.8896855,217.787224 64.0370233,225 82.6417302,225 Z"
      transform="translate(83.000000, 113.000000) scale(1, -1) translate(-83.000000, -113.000000) "
    />
  </g>
  <g v-else-if="hairStyle == 'bald'" />
  <g
    v-else
    transform="translate(118.500000, 121.000000) scale(-1, 1) translate(-118.500000, -121.000000) translate(37.000000, 28.000000)"
    class="avatar__hair"
  >
    <path
      d="M123.365399,91.1796637 C153.186326,91.2612341 177.322561,77.7124587 177.275257,60.917257 C177.227813,44.1220553 153.081436,23.5442309 123.260508,23.4620452 C93.4395809,23.3803505 69.2366818,43.8253238 69.2839861,60.6205254 C69.3314291,77.4157271 93.5444713,91.0973538 123.365399,91.1796637 Z"
      transform="translate(123.279621, 57.320854) scale(-1, -1) rotate(-96.000000) translate(-123.279621, -57.320854) "
    />
    <path
      d="M78,186 C121.07821,186 156,148.602915 156,102.471272 C156,56.3396294 121.202612,0 78.1244019,0 C35.0461914,0 0,56.3396294 0,102.471272 C0,148.602915 34.9217895,186 78,186 Z"
      transform="translate(78.000000, 93.000000) scale(1, -1) translate(-78.000000, -93.000000) "
    />
  </g>
</template>

<script>
export default {
  props: {
    hairStyle: {
      type: String,
      default: ""
    }
  }
};
</script>
