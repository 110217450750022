<template>
  <div class="message-text">
    <p v-for="(line, index) in parsedMessage" :key="index" v-html="line" />
  </div>
</template>

<script>
import { parseMsg } from "@/common/services/message.js";

import messageMixin from "./message-mixin.js";

export default {
  mixins: [messageMixin],
  computed: {
    messageText() {
      return this.message.data.text;
    },
    parsedMessage() {
      return this.messageText
        ? [this.messageText]
        : parseMsg(this.message.data.content);
    }
  }
};
</script>
