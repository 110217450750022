import Autolinker from "autolinker";

export function parseMsg(msg) {
  return msg.split(/\\n/).map(part => part.trim());
}

/**
 *
 * We remove this rule because we now have hiperlinks in answers, so it should
 * be enough but I have to test it. I will leave it here for now.
 */

export function parseLink(part) {
  let msg = Autolinker.link(part, {
    truncate: { length: 20 },
    urls: { schemeMatches: true, wwwMatches: true, tldMatches: false }
  });

  const linkStartString = "<a ";
  const linkEndString = "</a>";
  const targetString = "target='_blank' ";
  let linkStartPosition = msg.indexOf(linkStartString);
  let linkEndPosition = msg.indexOf(linkEndString, linkStartPosition);
  while (
    linkStartPosition != -1 &&
    linkEndPosition != -1 &&
    linkStartPosition < linkEndPosition
  ) {
    let targetAttributePosition = msg.indexOf("target=", linkStartPosition);
    let hasTargetAttribute =
      linkStartPosition < targetAttributePosition &&
      targetAttributePosition < linkEndPosition;

    if (!hasTargetAttribute) {
      let index = linkStartPosition + linkStartString.length;
      msg = msg.substring(0, index) + targetString + msg.substr(index);
    }

    //next link
    linkStartPosition = msg.indexOf(
      linkStartString,
      linkEndPosition + (!hasTargetAttribute ? targetString.length : 0)
    );

    linkEndPosition = msg.indexOf(linkEndString, linkStartPosition);
  }
  return msg;
}
